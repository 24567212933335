$(document).ready(function(){

    if($('.datetimepicker-range').length){
        var starts_at = $('.starts_at').val();
        if(!starts_at){
            starts_at = moment().format('YYYY-MM-DD HH:mm:ss');
            $('.starts_at').val(starts_at);
        }

        var ends_at = $('.ends_at').val();
        if(!ends_at){
            ends_at = moment().format('YYYY-MM-DD HH:mm:ss');
            $('.ends_at').val(ends_at);
        }

        $('.datetimepicker-range').daterangepicker(
            {
                timePicker: true,
                timePicker24Hour: true,
                timePickerSeconds: true,
                timePickerIncrement: 1,
                autoApply: true,
                locale: time_translations,
                startDate: starts_at,
                endDate: ends_at
            },
            function(start, end, label) {
                $('.starts_at').val(start.format('YYYY-MM-DD HH:mm:ss'));
                $('.ends_at').val(end.format('YYYY-MM-DD HH:mm:ss'));
            }
        );
    }

    if($('.datetimepicker-single').length){
        var single_at = $('.single_at').val();
        if(!single_at){
            single_at = moment().format('YYYY-MM-DD HH:mm:ss');
            $('.single_at').val(single_at);
        }

        $('.datetimepicker-single').daterangepicker(
            {
                singleDatePicker: true,
                timePicker: true,
                timePicker24Hour: true,
                timePickerSeconds: true,
                timePickerIncrement: 1,
                autoApply: true,
                locale: time_translations,
                startDate: single_at
            },
            function(start, end, label) {
                $('.single_at').val(start.format('YYYY-MM-DD HH:mm:ss'));
            }
        );
    }

});