$(document).ready(function(){
    if($('.dz-gallery').length){
        var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');

        var previewTemplate = $('#file-row-template').html();
        var palceholderTemplate = $('#file-row-placeholder-template').html();

        var myDropzone = new Dropzone(document.body, { // Make the whole body a dropzone
            url: "/admin/gallery/store_image", // Set the url
            thumbnailWidth: 50,
            thumbnailHeight: 50,
            parallelUploads: 20,
            autoProcessQueue: true,
            previewTemplate: previewTemplate,
            sending: function(file, xhr, formData) {
                formData.append("_token", CSRF_TOKEN);
            },
            success: function(file, response){
                $template = $(file.previewElement);
                $template.find('[data-dz-uploadprogress]').parent().fadeOut(1000);
                if(response.result == 'Ok'){
                    $template.data('filename', response.filename);
                    $template.find('[dz-hidden-input]').val(response.filename);
                    $template.find('[dz-title-input]').attr('name', 'gallery_image_titles['+response.filename+']');
                }
                if(response.result == 'Error'){
                    $template.find('[dz-error-message]').html(response.error).show();
                    $template.find('input').remove();
                    $template.delay(5000).fadeOut(1000);
                }
            },
            error: function(file, errorMessage){
                $template = $(file.previewElement);
                $template.find('[dz-error-message]').val('Error!');
            },
            autoQueue: true,
            previewsContainer: "#previews", // Define the container to display the previews
            clickable: ".fileinput-button" // Define the element that should be used as click trigger to select files.
        });

        $(".uploaded_files").sortable({
            handle: '.handle',
            placeholder: palceholderTemplate,
            onDrag: function ($item, position, _super, event) {
                position.left = position.left - $item.width;
                $item.css(position);
                console.log(event);
            }
        });
    }
});